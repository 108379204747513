
import {defineComponent} from "vue"
import {mapActions, mapState} from "vuex";
import Input from "@/views/Input.vue";
import MedItemModel from "@/models/MedItemModel";


export default defineComponent({
  name: "Start",
  components: {Input},
  computed: {
    ...mapState(['medItemsList']),
    pagedTableData(): Array<MedItemModel> | [] {
      let page = this.page
      let pageSize = this.pageSize
      return this.medItemsList.slice(pageSize * page - pageSize, pageSize * page)
    },

  },
  props: {},
  created() {
    console.log('createdd====', this.medItemsList)
  },
  data() {
    return {
      page: 1,
      pageSize: 50
    }
  },
  methods: {
    ...mapActions(['getMedItems']),

    setPage(val: number) {
      this.page = val
    },
    handleSizeChange(val: number) {
      this.pageSize = val

    },
  },

});
