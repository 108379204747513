<template>
  <el-row>
     <el-col :span="20">
    <p>Дата базы КТРУ {{dbDate}}</p>
       </el-col>
    <el-col :span="20">
  <el-form label-width="200px" >
      <el-tooltip
    :disabled="disabled"
    content="Введите слова для поиска и нажмите Enter"
    placement="bottom"
    effect="light"
  >
    <el-form-item label="Слова для поиска">

      <el-input
          v-on:keydown.enter="downEnter"
          @change="setSearchSt"
            placeholder="Введите слова для поиска"
            v-model="words"></el-input>
    </el-form-item>
      </el-tooltip>
      <el-form-item label="Слова исключения">
        <el-input
            v-on:keydown.enter="downEnter"
            @change="setExcludeWords"
             placeholder="Введите слова для исключения"
            v-model="excludeWords"></el-input>
      </el-form-item>

  </el-form>
    </el-col>
  </el-row>
</template>

<script>

import { defineComponent, ref } from 'vue'
import {mapActions, mapMutations, mapState} from "vuex";

// export default {
//
//   data() {
//     return {
//       form: {
//
//         excludeWords: "",
//         CountResult: 10
//       }
//     }
//   }
// }

export default defineComponent ({
    name: "Input",
  computed: {
    ...mapState(['searchSt', 'dbDate'])
  },
  methods: {
    ...mapMutations(['setSearchSt', "setExcludeWords"]),
    ...mapActions(['getMedItems', 'getDBDate']),
    downEnter(){
      console.log("Enter pressed")
      this.setSearchSt(this.words.toString())
      this.setExcludeWords(this.excludeWords)
      this.getMedItems()
    }
  },
  setup() {
    return {
      words: ref(''),
      excludeWords: ref('')
    }
  },
  mounted() {
      this.getDBDate()
  }
})
</script>

<style scoped>

</style>