import {createApp} from 'vue'
import router from './router'
import store from './store'
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import App from "./App.vue";
// createApp(App)
// .use(store)
// .use(router)
// .mount("#app");
createApp(App)
    .use(ElementPlus)
    .use(store)
    .use(router)
    .mount("#app");