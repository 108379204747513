import {createStore} from 'vuex'
import MedItemModel from "@/models/MedItemModel";
import axios from "axios";
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default createStore({
    state: {
        medItemsList: [] as MedItemModel[],
        searchSt: "",
        excludeWords: "",
        resultCount: 1000,
        dbDate: "",
    },
    mutations: {
        refrashmedItemsLists(state, val: MedItemModel[]) {
            state.medItemsList = val
            console.log("mutations", state.medItemsList)
        },
        setDBDate(state, val: string) {
            state.dbDate = val
            console.log('dbDate', state.dbDate)
        },
        setSearchSt(state, val: string) {
            state.searchSt = val
        },
        setExcludeWords(state, val: string) {
            state.excludeWords = val
            console.log("changed exclude words", val)
        },
        clearItemsList(state) {
            state.medItemsList = []
        }

    },
    actions: {
        getDBDate({commit},) {
            interface ServerResponse {
                data: ServerData
            }

            interface ServerData {
                current_date: string,
            }

            axios.request<ServerData>({
                method: 'get',
                url: 'https://ktru-parse.rdbiovitrum.ru/api/get-db-date/',

            }).then((response: ServerResponse) => {
                const {data} = response
                commit('setDBDate', data.current_date)
            })
        },
        getMedItems({commit},) {
            function compare(a: MedItemModel, b: MedItemModel): number {
                let comparison = 0;
                if (a.name > b.name) {
                    comparison = 1;
                } else if (a.name < b.name) {
                    comparison = -1;
                }
                return comparison;

            }

            commit("clearItemsList")

            interface ServerResponse {
                data: ServerData
            }

            interface DataQwe {
                data_search_results?: Array<MedItemModel> | null;
            }

            interface ServerData {
                data: DataQwe,
            }

            axios.request<ServerData>({
                method: 'get',
                url: 'https://rzn-mi-reestr.n1.rdbiovitrum.ru/api/get_search_results/',
                params: {
                    "search_str": this.state.searchSt,
                    "exclude_words": this.state.excludeWords,
                    "result_count": this.state.resultCount
                },

            })
                .then((response: ServerResponse) => {
                    const {data} = response
                    console.log('clean data', data.data.data_search_results?.sort(compare))
                    commit('refrashmedItemsLists', data.data.data_search_results?.sort(compare))
                    if (data.data.data_search_results?.length) {
                        createToast('Данные получены', {
                            type: "info",
                            showIcon: true,
                            timeout: 1500
                        })
                    } else {
                        createToast('Ничего не нашел', {
                            type: "warning",
                            showIcon: true,
                            timeout: 1500
                        })
                    }

                })

        },

    },
    modules: {}
})
